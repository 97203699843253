<template>
  <div class="news">
    <div class="list">
      <div class="container_wrap">
        <Header />

        <div class="list_container">
          <el-row :gutter="10">
            <el-col :span="7">
              <ul class="side_muen marb_15">
                <li>
                  <a href="/list?id=7&navName=新闻中心&groupName=集团动态">集团动态</a>
                </li>
                <li>
                  <a href="/list?id=8&navName=新闻中心&groupName=子%28分%29公司动态">子(分)公司动态</a>
                </li>
                <li>
                  <a href="/list?id=9&navName=新闻中心&groupName=公示公告">公示公告</a>
                </li>
                <li>
                  <a href="/list?id=10&navName=新闻中心&groupName=政策法规">政策法规</a>
                </li>
                <li>
                  <a href="/list?id=11&navName=新闻中心&groupName=媒体聚焦">媒体聚焦</a>
                </li>
                <li>
                  <a href="/list?id=12&navName=新闻中心&groupName=视频集锦">视频集锦</a>
                </li>
                <li>
                  <a href="/list?id=13&navName=新闻中心&groupName=行业动态">行业动态</a>
                </li>
              </ul>
              <div class="marb_15">
                <NewsCompany />
              </div>
              <div class="marb_15">
                <Rank />
              </div>
              
            </el-col>

            <el-col :span="10">
              <div class="sec_wrap company_wrap">
                <div class="sec_tit">
                  <span><a href="/list?id=7&navName=新闻中心&groupName=集团动态">集团动态</a></span>
                  <div class="fr company_more">
                    <a href="/list?id=7&navName=新闻中心&groupName=集团动态">更多{{'>>'}}</a>
                  </div>
                </div>
                <ul class="list_l_jtdt">
                  <li
                    class="single-line"
                    v-for="(item, index) in listJtdt"
                    :key="index"
                  >
                    <a v-if="!item.is_link || item.is_link === 0" target="_blank" :href="`/detail?id=${item.id}&navName=新闻中心&groupName=集团动态`">{{ item.title }}</a>
                    <a v-if="item.is_link === 1" target="_blank" :href="item.link_url">{{ item.title }}</a>
                  </li>
                </ul>
              </div>
              <div class="sec_wrap company_wrap">
                <div class="sec_tit">
                  <span><a href="/list?id=8&navName=新闻中心&groupName=子%28分%29公司动态">子(分)公司动态</a></span>
                  <div class="fr company_more">
                    <a href="/list?id=8&navName=新闻中心&groupName=子%28分%29公司动态">更多{{'>>'}}</a>
                  </div>
                </div>
                <ul class="list_l_jtdt">
                  <li
                    class="single-line"
                    v-for="(item, index) in listZfgsdt"
                    :key="index"
                  >
                    <a v-if="!item.is_link || item.is_link === 0" target="_blank" :href="`/detail?id=${item.id}&navName=新闻中心&groupName=子%28分%29公司动态`">{{ item.title }}</a>
                    <a v-if="item.is_link === 1" target="_blank" :href="item.link_url">{{ item.title }}</a>
                  </li>
                </ul>
              </div>
              <div class="sec_wrap company_wrap">
                <div class="sec_tit">
                  <span><a href="/list?id=9&navName=新闻中心&groupName=公示公告">公示公告</a></span>
                  <div class="fr company_more">
                    <a href="/list?id=9&navName=新闻中心&groupName=公示公告">更多{{'>>'}}</a>
                  </div>
                </div>
                <ul class="list_l_jtdt">
                  <li
                    class="single-line"
                    v-for="(item, index) in listGsgg"
                    :key="index"
                  >
                    <a v-if="!item.is_link || item.is_link === 0" target="_blank" :href="`/detail?id=${item.id}&navName=新闻中心&groupName=公示公告`">{{ item.title }}</a>
                    <a v-if="item.is_link === 1" target="_blank" :href="item.link_url">{{ item.title }}</a>
                  </li>
                </ul>
              </div>
              <div class="sec_wrap company_wrap">
                <div class="sec_tit">
                  <span><a href="/list?id=10&navName=新闻中心&groupName=政策法规">政策法规</a></span>
                  <div class="fr company_more">
                    <a href="/list?id=10&navName=新闻中心&groupName=政策法规">更多{{'>>'}}</a>
                  </div>
                </div>
                <ul class="list_l_jtdt">
                  <li
                    class="single-line"
                    v-for="(item, index) in listZcfg"
                    :key="index"
                  >
                    <a v-if="!item.is_link || item.is_link === 0" target="_blank" :href="`/detail?id=${item.id}&navName=新闻中心&groupName=政策法规`">{{ item.title }}</a>
                    <a v-if="item.is_link === 1" target="_blank" :href="item.link_url">{{ item.title }}</a>
                  </li>
                </ul>
              </div>
              <div class="sec_wrap company_wrap">
                <div class="sec_tit">
                  <span><a href="/list?id=11&navName=新闻中心&groupName=媒体聚焦">媒体聚焦</a></span>
                  <div class="fr company_more">
                    <a href="/list?id=11&navName=新闻中心&groupName=媒体聚焦">更多{{'>>'}}</a>
                  </div>
                </div>
                <ul class="list_l_jtdt">
                  <li
                    class="single-line"
                    v-for="(item, index) in listMtjj"
                    :key="index"
                  >
                    <a v-if="!item.is_link || item.is_link === 0" target="_blank" :href="`/detail?id=${item.id}&navName=新闻中心&groupName=媒体聚焦`">{{ item.title }}</a>
                    <a v-if="item.is_link === 1" target="_blank" :href="item.link_url">{{ item.title }}</a>
                  </li>
                </ul>
              </div>

            </el-col>

            <el-col :span="7">
              <div class="marb_15">
                <Notic />
              </div>
              <div class="marb_15">
                <NewsPic />
              </div>
            </el-col>
          </el-row>

        </div>

        <Footer />
      </div>
    </div>
  </div>
</template>

<script>
import Header from "@/components/header.vue";
import Footer from "@/components/footer.vue";
import Rank from "@/components/rank.vue";
import Notic from "@/components/notic.vue";
import NewsPic from "@/components/newsPic.vue";
import NewsCompany from "@/components/newsCompany.vue";

export default {
  name: 'news',
  components: {
    Header,
    Footer,
    Rank,
    Notic,
    NewsPic,
    NewsCompany
  },
  data(){
    return {
      listJtdt: [],
      listZfgsdt: [],
      listGsgg: [],
      listZcfg: [],
      listMtjj: [],
    }
  },
  mounted(){
    var _this = this

    _this.getDataJtdt()
    _this.getDataZfgsdt()
    _this.getDataGsgg()
    _this.getDataZcfg()
    _this.getDataMtjj()
  },
  methods: {
    async getDataJtdt() {
      var _this = this;
      var { data } = await _this.$http.post(
        _this.apiUrl + "/news/getNewsList",
        { page: 1, group_id: 7 }
      );
      _this.listJtdt = data.data.list.slice(0, 5);
    },
    async getDataZfgsdt() {
      var _this = this;
      var { data } = await _this.$http.post(
        _this.apiUrl + "/news/getNewsList",
        { page: 1, group_id: 8 }
      );
      _this.listZfgsdt = data.data.list.slice(0, 5);
    },
    async getDataGsgg() {
      var _this = this;
      var { data } = await _this.$http.post(
        _this.apiUrl + "/news/getNewsList",
        { page: 1, group_id: 9 }
      );
      _this.listGsgg = data.data.list.slice(0, 5);
    },
    async getDataZcfg() {
      var _this = this;
      var { data } = await _this.$http.post(
        _this.apiUrl + "/news/getNewsList",
        { page: 1, group_id: 10 }
      );
      _this.listZcfg = data.data.list.slice(0, 5);
    },
    async getDataMtjj() {
      var _this = this;
      var { data } = await _this.$http.post(
        _this.apiUrl + "/news/getNewsList",
        { page: 1, group_id: 11 }
      );
      _this.listMtjj = data.data.list.slice(0, 5);
    },

  }
}
</script>