<template>
  <div class="header">
    <div class="header_hd clearfloat">
      <div class="fl">
        <a href="https://m.ghatg.com/" class="mobile">手机版</a>
      </div>
      <div class="fr">
        <form
          class="user_wrap"
          name="form1"
          action="https://exmail.qq.com/cgi-bin/login"
          target="_blank"
          method="post"
          @submit="checkInput()"
        >
          <input type="hidden" name="firstlogin" value="false" />
          <input type="hidden" name="errtemplate" value="dm_loginpage" />
          <input type="hidden" name="aliastype" value="other" />
          <input type="hidden" name="dmtype" value="bizmail" />
          <input type="hidden" name="p" value="" />
          <span class="user_l user_name">账号：</span>
          <div class="user_input">
            <el-input
              v-model="inputName"
              name="uin"
              size="mini"
              placeholder="请输入账号"
            ></el-input>
          </div>
          @<span>ghatg.com</span>
          <input type="hidden" name="domain" value="ghatg.com" />
          <span class="user_l user_name">密码：</span>
          <div class="user_input">
            <el-input
              v-model="inputPassword"
              type="password"
              name="pwd"
              size="mini"
              placeholder="请输入密码"
            ></el-input>
          </div>
          <input type="submit" class="user_sure" name="" value="登录" />
        </form>

        <a
          class="user_forget"
          href="https://exmail.qq.com/cgi-bin/readtemplate?check=false&amp;t=bizmail_orz"
          target="_blank"
          >忘记密码？</a
        >
        <a
          target="_blank"
          href="/detail?id=6581&navName=公司概况&groupName=董事长信箱"
          class="mail_dsz"
          >董事长信箱</a
        >
      </div>
    </div>

    <div class="header_top clearfloat">
      <div class="fl">
        <router-link to="/"><img src="../assets/logo.jpg" alt=""/></router-link>
      </div>
      <div class="fr headerSearch">
        <el-input
          v-model="input"
          placeholder="请输入内容"
          style="width: 230px; margin-right: 10px;"
          @keyup.enter.native="enter"
        ></el-input>
        <el-button type="primary" @click="search">全站搜索</el-button>
      </div>
    </div>
    <div class="nav_box">
      <ul class="clearfloat">
        <li :class="{ active: active == 0 }">
          <div class="nav_grop">
            <h2><router-link to="/">网站首页</router-link></h2>
          </div>
        </li>

        <li
          v-for="(item, index) in navListN"
          :key="index"
          :class="{ active: active == item.id }"
        >
          <div
            class="nav_grop"
            @mouseenter="navShow(item.id)"
            @mouseleave="navClose()"
          >
            <h2>
              <a :href="item.url">{{ item.name }}</a>
            </h2>
            <el-collapse-transition>
              <div class="nav_down" v-show="navDown == item.id">
                <div
                  class="nav_down_wrap clearfloat"
                  v-show="item.group_list.length != 0"
                >
                  <p v-for="(item1, index1) in item.group_list" :key="index1">
                    <a
                      :href="
                        item1.url && item1.url != ''
                          ? `${item1.url}`
                          : `/list?id=${item1.id}&navName=${item.name}&groupName=${item1.name}`
                      "
                      v-if="item1.have_list == 0 && item1.is_link !== 1"
                      >{{ item1.name }}</a
                    >
                    <a
                      target="_blank"
                      :href="
                        item1.url && item1.url != ''
                          ? `${item1.url}`
                          : `/detail?id=${item1.id}&navName=${item.name}&groupName=${item1.name}&noList=1`
                      "
                      v-if="item1.have_list == 1 && item1.is_link !== 1"
                      >{{ item1.name }}</a
                    >
                    <a
                      :href="item1.url"
                      target="_blank"
                      v-if="item.name == '核心成员'"
                      >{{ item1.name }}</a
                    >
                    <a
                      :href="item1.link_url"
                      target="_blank"
                      v-if="item1.is_link === 1"
                      >{{ item1.name }}</a
                    >
                  </p>
                </div>
              </div>
            </el-collapse-transition>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "Header",
  data() {
    return {
      input: "",
      active: 0,
      navDown: 0,
      navList: [],
      navSever: [
        {
          name: "高速公路",
          id: "1"
        },
        {
          name: "特大桥",
          id: "2"
        },
        {
          name: "特长隧道",
          id: "3"
        },
        {
          name: "房建",
          id: "4"
        }
      ],
      navShow01: false,
      navShow02: false,
      navShow03: false,
      navShow04: false,
      navShow05: false,
      navShow06: false,
      navShow07: false,
      inputName: "",
      inputPassword: "",
      navListN: [
        {
          id: 2,
          name: "公司概况",
          isIndex: 0,
          url: "/company",
          group_list: [
            {
              id: 1,
              name: "集团概况",
              nav_id: 2,
              have_list: 1,
              create_time: "2020-12-22T07:10:13.037Z"
            },
            {
              id: 4,
              name: "领导成员",
              nav_id: 2,
              have_list: 1,
              create_time: "2020-12-22T07:10:41.071Z",
              url: "/member"
            },
            {
              id: 2,
              name: "组织机构",
              nav_id: 2,
              have_list: 0,
              create_time: "2020-12-22T07:10:23.364Z"
            },
            {
              id: 5,
              name: "大事记",
              nav_id: 2,
              have_list: 0,
              create_time: "2020-12-22T07:10:53.859Z"
            },
            {
              id: 3,
              name: "公司荣誉",
              nav_id: 2,
              have_list: 0,
              create_time: "2020-12-22T07:10:32.116Z"
            },
            {
              id: 6,
              name: "联系我们",
              nav_id: 2,
              have_list: 1,
              create_time: "2020-12-22T07:11:04.415Z"
            }
          ]
        },
        {
          id: 3,
          name: "新闻中心",
          isIndex: 0,
          url: "/news",
          group_list: [
            {
              id: 7,
              name: "集团动态",
              nav_id: 3,
              have_list: 0,
              create_time: "2020-12-22T07:11:14.391Z"
            },
            {
              id: 8,
              name: "子(分)公司动态",
              nav_id: 3,
              have_list: 0,
              create_time: "2020-12-22T07:11:41.023Z"
            },
            {
              id: 9,
              name: "公示公告",
              nav_id: 3,
              have_list: 0,
              create_time: "2020-12-22T07:11:55.371Z"
            },
            {
              id: 10,
              name: "政策法规",
              nav_id: 3,
              have_list: 0,
              create_time: "2020-12-22T07:12:09.118Z"
            },
            {
              id: 11,
              name: "媒体聚焦",
              nav_id: 3,
              have_list: 0,
              create_time: "2020-12-22T07:12:24.884Z"
            },
            {
              id: 12,
              name: "视频集锦",
              nav_id: 3,
              have_list: 0,
              create_time: "2020-12-22T07:12:35.425Z"
            },
            {
              id: 13,
              name: "行业动态",
              nav_id: 3,
              have_list: 0,
              create_time: "2020-12-22T07:12:47.228Z"
            }
          ]
        },
        {
          id: 4,
          name: "集团业务",
          isIndex: 0,
          url: "/business",
          group_list: [
            {
              id: 14,
              name: "公路",
              nav_id: 4,
              have_list: 0,
              create_time: "2020-12-22T07:12:55.466Z"
            },
            {
              id: 15,
              name: "航空",
              nav_id: 4,
              have_list: 0,
              create_time: "2020-12-22T07:13:02.484Z"
            },
            {
              id: 16,
              name: "旅游",
              nav_id: 4,
              have_list: 0,
              create_time: "2020-12-22T07:13:18.405Z"
            },
            {
              id: 17,
              name: "金融",
              nav_id: 4,
              have_list: 0,
              create_time: "2020-12-22T07:13:25.749Z"
            },
            {
              id: 18,
              name: "保险",
              nav_id: 4,
              have_list: 0,
              create_time: "2020-12-22T07:13:33.039Z",
              is_link: 1,
              link_url: "http://www.ypic.cn/"
            },
            {
              id: 19,
              name: "融资",
              nav_id: 4,
              have_list: 0,
              create_time: "2020-12-22T07:13:48.824Z"
            },
            {
              id: 376,
              name: "新能源",
              nav_id: 4,
              have_list: 0,
              create_time: "2020-12-22T07:13:48.824Z"
            },
          ]
        },
        {
          id: 9,
          name: "巡视巡察",
          isIndex: 0,
          url: "/list?id=516&groupName=巡视巡察",
          group_list: []
        },
        {
          id: 5,
          name: "招贤纳士",
          isIndex: 0,
          url: "/list?id=20&navName=招贤纳士&groupName=人才招聘",
          group_list: [
            {
              id: 20,
              name: "人才招聘",
              nav_id: 5,
              have_list: 0,
              create_time: "2020-12-22T07:14:03.529Z"
            },
            {
              id: 21,
              name: "下载中心",
              nav_id: 5,
              have_list: 0,
              create_time: "2020-12-22T07:14:15.325Z"
            }
          ]
        },
        {
          id: 6,
          name: "核心成员",
          isIndex: 0,
          url: "/list?id=163&groupName=核心成员",
          group_list: []
        },
        {
          id: 8,
          name: "企业文化",
          isIndex: 0,
          url: "/list?id=255&navName=企业文化&groupName=文化理念",
          group_list: [
            {
              id: 255,
              name: "文化理念",
              nav_id: 8,
              have_list: 0,
              create_time: "2020-12-22T07:14:03.529Z"
            },
            {
              id: 264,
              name: "视觉识别",
              nav_id: 8,
              have_list: 0,
              create_time: "2020-12-22T07:14:03.529Z"
            },
            {
              id: 265,
              name: "员工行为规范",
              nav_id: 8,
              have_list: 1,
              create_time: "2020-12-22T07:14:03.529Z"
            },
            {
              id: 266,
              name: "宣传片",
              nav_id: 8,
              have_list: 1,
              create_time: "2020-12-22T07:14:03.529Z"
            },
            {
              id: 256,
              name: "文化活动",
              nav_id: 8,
              have_list: 0,
              create_time: "2020-12-22T07:14:15.325Z"
            }
          ]
        },
        {
          id: 7,
          name: "学习园地",
          isIndex: 0,
          url: "/list?id=26&groupName=学习园地",
          group_list: []
        }
      ]
    };
  },
  mounted: function() {},
  created: function() {
    var _this = this;

    // _this.active = _this.$route.meta ? _this.$route.meta.text : '1'
    _this.getlistZfgs();
    _this.getNavList();
  },
  methods: {
    // 二级导航展开
    navShow(d) {
      let _this = this;
      // _this[d] = true
      _this.navDown = d;
    },
    // 二级导航收起
    navClose() {
      let _this = this;
      // _this[d] = false
      _this.navDown = 0;
    },
    // 搜索
    search() {
      var _this = this;
      if (_this.input.match(/^\s*$/)) {
        this.$message({
          message: "请输入搜索内容",
          type: "warning"
        });
      } else {
        window.location.href = "/search?title=" + _this.input;
      }
    },
    // 服务领域-nav
    async getSeverNav() {
      var _this = this;
      var { data } = await _this.$http.post(
        _this.apiUrl + "/project/getProjectTypeList"
      );
      if (data.code == 200) {
        _this.navSever = data.data;
      }
    },

    async getNavList() {
      var _this = this;
      var { data } = await _this.$http.post(_this.apiUrl + "/nav/getNavList", {
        company_id: 1
      });
      if (data.code == 200) {
        _this.navList = data.data;
      }
    },
    enter(e) {
      var _this = this;
      // 兼容FF和IE和Opera
      var event = e || window.event;
      var key = event.which || event.keyCode || event.charCode;
      if (key == 13) {
        /*Do something. 调用一些方法*/
        _this.search();
      }
    },
    checkInput() {
      var b = document.form1.uin,
        a = document.form1.pwd;
      if (0 == b.value.length) {
        b.focus();
        return false;
      }
      if (0 == a.value.length) {
        a.focus();
        return false;
      }
      document.form1.submit();
      setTimeout(" document.form1.pwd.value = '' ", 500);
      return false;
    },

    // 核心成员-列表
    async getlistZfgs() {
      var _this = this;
      var { data } = await _this.$http.post(
        _this.apiUrl + "/company/getCompanyList"
      );
      _this.navListN.forEach(function(line, index) {
        if (line.id == 6) {
          _this.navListN[index].group_list = data.data;
        }
      });
    }
  },
  watch: {
    $route: function() {
      this.active = this.$route.meta ? this.$route.meta.text : "1";
    }
  }
};
</script>
<style scoped>
.header {
  border-top: 4px solid #096cc4;
}
.headerSearch {
  margin-top: 30px;
}

.nav_box {
  height: 38px;
  background-color: #1970b9;
  line-height: 38px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  margin: 0 10px;
}
.nav_box ul li {
  float: left;
  width: 11.1%;
  color: #fff;
  text-align: center;
  position: relative;
}
/* .nav_box ul li a {
    display: block;
  } */
.nav_grop {
  width: 82px;
  height: 28px;
  line-height: 28px;
  margin: 5px auto 0;
  position: relative;
}
.nav_box ul li h2 {
  height: 100%;
  font-size: 16px;
  border-radius: 4px;
  /* border-bottom-left-radius: 0;
    border-bottom-right-radius: 0; */
  overflow: hidden;
  /* border: 1px solid #1970b9; */
}
.nav_box ul li h2 a {
  display: block;
  color: #fff;
}
.nav_grop:hover h2 {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.nav_grop:hover h2 a {
  color: #1970b9;
  background-color: #fff;
  font-weight: 600;
}
.nav_box ul li.active h2 a {
  color: #fff;
  background-color: #ff8c00;
  font-weight: 600;
}
.nav_down {
  position: absolute;
  width: 268px;
  left: -1px;
  top: 28px;
  z-index: 55;
}
.nav_down_wrap {
  padding: 10px 0;
  border: 1px solid rgb(35, 96, 176);
  border-top: none;
  background-color: #fff;
}
.nav_down p {
  float: left;
  min-width: 120px;
  line-height: 26px;
  margin-left: 10px;
  border-top: 1px solid #fff;
  text-align: left;
}
.nav_down p a {
  color: #333;
}
.nav_down p a:hover {
  color: #1970b9;
  text-decoration: underline;
}
.header_hd {
  height: 34px;
  line-height: 34px;
  background-image: url("../assets/img/st_03.jpg");
  background-repeat: repeat-x;
  background-size: auto 100%;
  padding: 0 25px;
  font-size: 12px;
}
.header_top {
  padding: 10px;
}
.mobile {
  display: block;
  height: 20px;
  line-height: 20px;
  padding: 0 8px;
  border: 1px solid #dedede;
  margin-top: 6px;
  font-size: 12px;
  font-weight: 600;
}

.mobile:hover {
  color: #d60f0f;
}
.user_input {
  display: inline-block;
  width: 120px;
}
.user_l {
  display: inline-block;
  margin-left: 10px;
}
.user_sure {
  margin: 0 4px 0 6px;
  height: 26px;
  line-height: 26px;
  padding: 0 10px;
  border: 1px solid #b6b6b6;
  cursor: pointer;
  vertical-align: top;
  margin-top: 3px;
  background-color: #e9e9e9;
  border-radius: 2px;
}
.user_sure:hover {
  background-color: #dddddd;
}
.user_forget:hover {
  color: #d60f0f;
  text-decoration: underline;
}
.mail_dsz {
  display: inline-block;
  height: 20px;
  line-height: 20px;
  padding: 0 8px;
  border: 1px solid #dedede;
  margin-top: 6px;
  font-size: 12px;
  color: #d60f0f;
  margin-left: 30px;
}
.mail_dsz:hover {
  border-color: #d60f0f;
}
.user_wrap {
  display: inline-block;
}
</style>
