<template>
  <div class="jyxc">
    <div class="jyxc_hd">
      <img src="../assets/img/jyxc-beijing.jpg" alt="">
    </div>
    <div class="container_wrap">
      <div class="jyxc_wrap_hd">
        建言献策
      </div>
      <div class="jyxc_wrap">
        <div class="nr">
          <h2 align="center">我为公航旅“十四五”规划建言献策</h2>
					<p>“十四五”时期，是我国在全面建成小康社会基础上开启全面建设社会主义现代化新征程的关键时期，是集团公司抢抓政策机遇，推进高质量发展，努力实现“三大步、两翻番”中第三步，“再翻番”奋斗目标的开局之年。为贯彻落实习近平总书记对“十四五”规划编制工作的重要指示精神，提高规划编制的科学性和社会参与度，公航旅集团诚挚邀请公司广大职工为公航旅“十四五”规划建言献策。现将有关事项公告如下。</p>
					<p>一、<strong>时间安排</strong></p><p>根据集团公司“十四五”规划编制工作总体安排，征集时间为<strong>2020年11月18日起至2020年11月28日</strong>。</p>
					<p>二、<strong>征集内容</strong></p>
					<p>结合当前国有经济发展环境和公司发展基础，围绕“加强党的领导和完善公司治理、结构调整转型升级、传统产业转型高质量发展、提升产业基础能力和产业链现代化水平、全面落实创新驱动战略、提高企业活力和效率、提升国企改革综合成效、补短板、强弱项、协同发展、两新一重”等方面，提出意见建议。意见建议可以是三言两语的真知灼见，也可以是言之成理的论述文稿。</p>
					<p>三、<strong>征集渠道</strong></p>
					
					<p>请通过<strong>企业微信</strong>发送至集团公司规划发展部邵华处；篇幅较长的，请发送电子邮件至<strong>466185057@qq.com</strong>。并注明：我为公航旅“十四五”规划建言献策。</p>
					
					<p>为便于联系、对接，请注明联系人姓名、联系方式，我们会对参与者的个人信息和建言内容严格保密。</p>
                  
          <p><br></p><p style="text-align: right;"><br></p>
          <p style="text-align: right;margin-right:120px;">甘肃省公路航空旅游投资集团有限公司</p>
					<p style="text-align: right;margin-right:205px;">2020年11月18日</p>	
		    </div>
      </div>

      <Footer />
    </div>
  </div>
</template>

<script>
import Footer from "@/components/footer.vue";

export default {
  name: 'jyxc',
  components: {
    Footer
    // Swiper
  },
  data(){
    return {

    }
  }
}
</script>

<style scoped>
  .jyxc_hd img {
    width: 100%;
  }
  .jyxc_wrap_hd {
    color: #fff;
    font-size: 16px;
    height: 40px;
    line-height: 40px;
    padding-left: 36px;
    background-color: #008ae2;
  }
  .nr {
    padding: 15px 30px;
    font-size: 18px;
    line-height: 2;
    color: #222;
  }
  .nr p {
    text-indent: 2em;
    font-size: 18px;
    line-height: 36px;
    margin: 18px 0;
  }
  .nr h2 {
    margin: 30px 0;
    line-height: 56px;
    font-size: 30px;
    font-weight: 600;
  }
</style>