<template>
  <div class="list">
    <div class="container_wrap">
      <Header />

      <div class="list_container">
        <el-row :gutter="15">
          <el-col :span="7">
            <div class="marb_15">
              <NewsCompany />
            </div>

            <div class="marb_15">
              <NewsPic />
            </div>
          </el-col>
          <el-col :span="17">
            <div class="list_hd">
              <span class="list_hd_posi"></span>
              <span class="list_hd_page"><a href="/">首页</a> > </span>
              <span class="list_hd_page"
                ><a href="">专题专栏</a> >
              </span>
              <span class="list_hd_page">列表</span>
            </div>

            <div class="special_list">
              <el-row :gutter="30">
                <el-col class="special_list_inner" :span="12" v-for="(item,index) in picList" :key="index">
                  <a v-if="!item.is_link || item.is_link === 0" :href="`/list?id=${item.id}&navName=专题专栏&groupName=${item.name}`" :title="item.name">
                    <img :src="item.img">
                  </a>
                  <a v-if="item.is_link === 1" target="_blank" :href="item.link_url" :title="item.name">
                    <img :src="item.img">
                  </a>
                </el-col>
              </el-row>
            </div>

          </el-col>
        </el-row>
      </div>

      <Footer />
    </div>
  </div>
</template>

<script>
import Header from "@/components/header.vue";
import Footer from "@/components/footer.vue";
import NewsPic from "@/components/newsPic.vue";
import NewsCompany from "@/components/newsCompany.vue";

export default {
  name: "special",
  components: {
    Header,
    Footer,
    NewsPic,
    NewsCompany
  },
  data() {
    return {
      name1: "专题专栏",
      name2: "",
      total: 0,
      pageSize: 20,
      currentPage: 1,
      list: [],
      newsJtdt: [],
      picList: [
        {
          id: 620,
          name: '学习宣传贯彻党的二十届三中全会精神',
          img: require("../assets/img/temp/zz_zt_esjszqh.jpg"),
          is_link: 0,
          link_url: ""
        },
        {
          id: 570,
          name: '党纪学习教育',
          img: require("../assets/img/temp/zt_djxxjy.png"),
          is_link: 1,
          link_url: "/partyLearn"
        },
        {
          id: 569,
          name: '普法宣传',
          img: require("../assets/img/temp/zt_pfxc.jpg"),
          is_link: 0,
          link_url: ""
        },
        {
          id: 487,
          name: '学习贯彻习近平新时代中国特色社会主义思想主题教育',
          img: require("../assets/img/temp/zt_xjpjs.png"),
          is_link: 0,
          link_url: ""
        },
        {
          id: 426,
          name: '“三抓三促”行动进行时',
          img: require("../assets/img/temp/zt_szsc_x_001.jpg"),
          is_link: 0,
          link_url: ''
        },
        {
          id: 619,
          name: '“反电诈行动”集中宣传月活动',
          img: require("../assets/img/temp/zt_fdzhd.jpg"),
          is_link: 0,
          link_url: ""
        },
        {
          id: 373,
          name: '学习宣传贯彻中国共产党第二十次全国代表大会精神',
          img: require("../assets/img/img_dj_esd.png"),
          is_link: 0,
          link_url: ''
        },
        {
          id: 271,
          name: '档案工作',
          img: require("../assets/img/zt_dagz.jpg"),
          is_link: 1,
          link_url: '/files'
        },
        {
          id: 270,
          name: '党建融入生产经营典型创建活动',
          img: require("../assets/img/zz_zt_djhd.png"),
          is_link: 0,
          link_url: ''
        },
        {
          id: 259,
          name: '奋进新征程建功新时代',
          img: require("../assets/img/zz_zt_fjxsd.jpg"),
          is_link: 0,
          link_url: ''
        },
        {
          id: 181,
          name: '学习宣传贯彻党的十九大精神',
          img: require("../assets/img/zz_zt_img01.png"),
          is_link: 0,
          link_url: ''
        },
        {
          id: 182,
          name: '学习宣传贯彻党的十九届四中全会精神',
          img: require("../assets/img/zz_zt_img02.png"),
          is_link: 0,
          link_url: ''
        },
        {
          id: 183,
          name: '学习宣传贯彻党的十九届五中全会精神',
          img: require("../assets/img/zz_zt_img03.png"),
          is_link: 0,
          link_url: ''
        },
        {
          id: 184,
          name: '学习宣传贯彻党的十九届六中全会精神',
          img: require("../assets/img/zz_zt_img04.png"),
          is_link: 0,
          link_url: ''
        },
        {
          id: 185,
          name: '“不忘初心、牢记使命”主题教育',
          img: require("../assets/img/zz_zt_img05.png"),
          is_link: 0,
          link_url: ''
        },
        {
          id: 186,
          name: '党史学习教育',
          img: require("../assets/img/zz_zt_img06.png"),
          is_link: 1,
          link_url: '/partyNews'
        },
        {
          id: 507,
          name: '公航旅集团《信访工作条例》落实年',
          img: require("../assets/img/temp/zt_xfgzlsn.jpg"),
          is_link: 1,
          link_url: "/petition"
        },
        {
          id: 187,
          name: '精神文明建设',
          img: require("../assets/img/zz_zt_img07.png"),
          is_link: 0,
          link_url: ''
        },
        {
          id: 188,
          name: '勠力同心 战胜疫情',
          img: require("../assets/img/zz_zt_img08.png"),
          is_link: 0,
          link_url: ''
        },
        {
          id: 189,
          name: '众志成城 防汛救灾',
          img: require("../assets/img/zz_zt_img09.png"),
          is_link: 0,
          link_url: ''
        },
        {
          id: 190,
          name: '脱贫攻坚帮扶工作',
          img: require("../assets/img/zz_zt_img10.png"),
          is_link: 0,
          link_url: ''
        },
        {
          id: 191,
          name: '壮丽七十年，逐梦新时代',
          img: require("../assets/img/zz_zt_img11.png"),
          is_link: 0,
          link_url: ''
        },
        {
          id: 192,
          name: '保密法律法规',
          img: require("../assets/img/zz_zt_img12.png"),
          is_link: 0,
          link_url: ''
        },
        {
          id: 193,
          name: '职工电子书屋',
          img: require("../assets/img/zz_zt_img13.png"),
          is_link: 1,
          link_url: 'https://wk3.bookan.com.cn/?id=22195&token=&productId=5!#/'
        },
      ]
    };
  },
  mounted() {
    // var _this = this;
    // _this.getZtzlNews();
  },
  methods: {
    async getZtzlNews(){
      var _this = this;
      var { data } = await _this.$http.post(_this.apiUrl + '/group/getGrouplistByNavId', {id: 20210202});
      _this.picList = data.data;
    },
  },
};
</script>

<style scoped>
</style>