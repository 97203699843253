<template>
  <div id="app" :class='{"appGrey":isGrey}'>
    <!-- <button @click="onGrey">置灰</button> -->
    <router-view />
  </div>
</template>

<script>
export default {
  name: "app",
  data(){
    return {
      isGrey: false
    }
    
  },
  mounted(){
    var _this = this
    document.title = "甘肃省公路航空旅游投资集团有限公司";
    _this.onGrey();
  },
  methods: {
    async onGrey(){
      var _this = this
      var { data } = await _this.$http.post(_this.apiUrl + '/tools/toGray', {company_id: 1});
      if(data && data.data[0].gray === 1){
        _this.isGrey = true
      }else{
        _this.isGrey = false
      }
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
.appGrey {
  filter: grayscale(100%);
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  -o-filter: grayscale(100%);
  filter: url("data:image/svg+xml;utf8,#grayscale");
  filter: progid: DXImageTransform.Microsoft.BasicImage(grayscale=1);
  -webkit-filter: grayscale(1);
}
</style>
