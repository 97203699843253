import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import "./assets/css/style_theme/theme/index.css";
import "swiper/dist/css/swiper.css";

// css
import "./assets/css/quill.bubble.css";
import "./assets/css/quill.core.css";
import "./assets/css/quill.snow.css";
import "./assets/css/publish.css";
import "./assets/css/style.css";

// 图片无缝轮播
import scroll from 'vue-seamless-scroll'

import axios from 'axios'

Vue.prototype.$http = axios;
// Vue.prototype.apiUrl = "http://192.168.31.16:21011";
// Vue.prototype.apiUrl = "http://api.ghatg.xiaoshuzn.com";
// Vue.prototype.apiUrl = "https://api.zq.ghatg.com";
Vue.prototype.apiUrl = "https://apizq.ghatg.com";

// 日期dayjs
import dayjs from "dayjs"
Vue.prototype.dayjs = dayjs;

// 生成二维码
import VueQriously from 'vue-qriously'
Vue.use(VueQriously)

Vue.use(ElementUI, scroll);

Vue.config.productionTip = false;

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount("#app");