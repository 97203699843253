<template>
  <div class="ranking">
    <div class="sec_wrap">
      <div class="sec_tit">
        <span><a href="">访问排行</a></span>
      </div>
      <ul class="list_l_jtdt">
        <li
          class="single-line"
          v-for="(item, index) in list"
          :key="index"
        >
          <a target="_blank" :href="`/detail?id=${item.id}&navName=新闻中心&groupName=集团动态`">{{ item.title }}</a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ranking',
  data(){
    return {
      list: []
    }
  },
  mounted(){
    var _this = this
    _this.getList();

  },
  methods: {
  async getList(){
        var _this = this
        var { data } = await _this.$http.post(_this.apiUrl + '/report/getVisitRanking', {company_id: 1});
        if(data.code == 200){
          _this.list = data.data
        }
      },

  }
}
</script>