<template>
  <div class="footer clearfloat">
    <div class="fl footer_inner01">
      <img src="../assets/img/footer_logo.gif" alt="">
    </div>
    <div class="fl footer_inner02">
      <p>
        版权所有：（C）甘肃省公路航空旅游投资集团有限公司 2016
      </p>
      <p>
        联系地址：甘肃省兰州市城关区南昌路1716号
      </p>
      <p>
        联系电话：0931—8864009、0931—8860770
      </p>
      <p>
        备案序号：<a href="http://beian.miit.gov.cn">陇ICP备11000302号-1</a>
      </p>
      <p style="margin-top: 4px;">
        <img src="../assets/img/ico_ga.png"/>
        甘公网安备 62010202000445号
      </p>
    </div>
    <div class="fl footer_inner04">
      <div class="footer_inner_pic">
        <img src="../assets/img/temp/img_pc.png" alt="">
        <p>PC端</p>
      </div>
      <div class="footer_inner_pic">
        <img src="../assets/img/temp/img_mobil.png" alt="">
        <p>手机端</p>
      </div>
      <div class="footer_inner_pic">
        <img src="../assets/img/temp/img_gzh.png" alt="">
        <p>微信公众号</p>
      </div>
    </div>
    <div class="fr footer_inner03">
      <p>
        <a v-show="footerShow01" href="/">返回首页</a><a v-show="footerShow02" href="">设为首页</a> | <a href="">联系我们</a> | <a href="">在线留言</a>
      </p>
      <!-- <p>
        支持<span class="footerLabel">IPv6</span>
      </p>
      <p>
        <img class="footerLabelImg" src="../assets/img/ico_network.png" alt=""> <span>支持</span><span class="footerLabel">IPv6</span>
      </p> -->
      <p class="footerIpv6">
        <img class="footerLabelImg" src="../assets/img/ico_network01.png" alt=""> <span>支持</span> <span class="footerIpv6Txt">IPv6</span>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
  data(){
    return {
      footerShow01: false,
      footerShow02: true
    }
  },
  mounted(){
    var _this = this
    if(_this.$route.name == 'jyxc' || _this.$route.name == 'Jyxc'){
      _this.footerShow01 = true
      _this.footerShow02 = false
    }
  }
}
</script>

<style scoped>
.footer {
  padding: 10px 10px;
  margin-top: 10px;
  border-top: 4px solid #096cc4;
}

.footer p {
  line-height: 24px;
}

.footer a {
  color: #096cc4;
}

.footer a:hover {
  text-decoration: underline;
}

.footer_inner02 {
  margin-left: 12px;
}
.footer_inner03 {
  text-align: right;
}
.footer_inner03 a {
  margin: 0 6px;
}
.footer_inner01 {
  margin-top: 19px;
}
.footerLabel {
  display: inline-block;
  height: 20px;
  line-height:20px;
  padding: 0 6px;
  margin-right: 6px;
  margin-left: 6px;
  font-weight: bold;
  border: 1px solid #096cc4;
  border-radius: 13px;
  color: #096cc4;
  vertical-align: middle;
}
.footerLabelImg {
  width: 20px;
  height: auto;
  vertical-align: middle;
}
.footer_inner03 span {
  vertical-align: middle;
}
.footerIpv6 {
  padding-right: 6px;
}
.footerIpv6Txt {
  font-weight: bold;
}
.footer_inner04 {
  width: 410px;
  text-align: center;
}
.footer_inner_pic {
  display: inline-block;
  text-align: center;
  margin: 0 12px;
}
.footer_inner_pic img {
  width: 106px;
  height: auto;
}
</style>